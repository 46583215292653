import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import './Header.css';
import sawBlade from './images/circpila.png';
import stena from './images/stena.png';
import { FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';

const Header = () => {
  const [isRotating, setIsRotating] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({ name: '', phone: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const sawBladeRef = useRef(null);

  useEffect(() => {
    setIsRotating(true);
    setTimeout(() => {
      setIsRotating(false);
    }, 1000);
  }, []);

  const handleMouseEnter = () => {
    setIsRotating(true);
  };

  const handleMouseLeave = () => {
    setIsRotating(false);
  };

  const handleClick = () => {
    setIsRotating(true);
    setTimeout(() => {
      setIsRotating(false);
    }, 1000);
  };

  const handleNavigation = (event, sectionId) => {
    event.preventDefault();
    const section = document.querySelector(sectionId);
    section.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleCallClick = () => {
    const callLink = document.createElement('a');
    callLink.href = 'tel:+79624448878';
    callLink.click();
  };

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/79624448878`, '_blank');
  };

  const handleFormInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const formatPhoneNumber = (phone) => {
    let phone2 = phone.replace(/\D/g, '');
    return "+" + phone2;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(formatPhoneNumber(formData.phone));
    const formattedData = {
      ...formData,
      phone: formatPhoneNumber(formData.phone),
    };

    fetch('sendMessage.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedData),
    })
      .then(response => response.json())
      .then(data => {
          setFormSubmitted(true);
          setTimeout(() => {
            setShowForm(false);
            setFormSubmitted(false);
          }, 2000);
      });
  };

  return (
    <>
      <header>
        <div className="logo-container">
          <div
            className="saw-blade-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
          >
            <img src={sawBlade} alt="Циркулярная пила" className={`saw-blade ${isRotating ? 'rotating' : ''}`} ref={sawBladeRef} />
          </div>
          <img src={stena} alt="Стена" />
          <div className="mobile-title">Алмазная резка Ставрополь</div>
          <div className="contact-info">
            <span className="phone-number" onClick={handleCallClick}>+7 (962) 444-88-78</span>
            <span className="callback-link" onClick={() => setShowForm(true)}>Заказать обратный звонок</span>
          </div>
        </div>
        <div className="nav-container">
          <nav>
            <ul>
              <li><a href="#uslugi" onClick={(e) => handleNavigation(e, '#uslugi')}>Услуги</a></li>
              <li><a href="#advantages" onClick={(e) => handleNavigation(e, '#advantages')}>Преимущества</a></li>
            <li><a href="#gallery" onClick={(e) => handleNavigation(e, '#gallery')}>Галерея</a></li>
              <li><a href="#contact" onClick={(e) => handleNavigation(e, '#contact')}>Контакты</a></li>
            </ul>
          </nav>
        </div>
      </header>
      <nav className="bottom-nav">
        <ul>
          <li><a href="#uslugi" onClick={(e) => handleNavigation(e, '#uslugi')}>Услуги</a></li>
          <li><a href="#advantages" onClick={(e) => handleNavigation(e, '#advantages')}>Преимущества</a></li>
         <li><a href="#gallery" onClick={(e) => handleNavigation(e, '#gallery')}>Галерея</a></li>
          <li><a href="#contact" onClick={(e) => handleNavigation(e, '#contact')}>Контакты</a></li>
        </ul>
      </nav>
     <div className="fixed-icons">
  <FaWhatsapp onClick={handleWhatsAppClick} className="icon whatsapp-icon " />
  <FaPhoneAlt onClick={handleCallClick} className="phone-icon" />
</div>

      {showForm && (
        <div className="overlay">
          <div className="form-container">
            {formSubmitted ? (
              <h2 className="success-message">Заявка отправлена</h2>
            ) : (
              <>
                <h2>Заказать обратный звонок</h2>
                <form onSubmit={handleFormSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Имя:</label>
                    <input type="text" id="name" name="name" value={formData.name} onChange={handleFormInputChange} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Телефон:</label>
                    <InputMask
                      mask="+7 999 999 9999"
                      value={formData.phone}
                      onChange={handleFormInputChange}
                    >
                      {(inputProps) => <input {...inputProps} type="tel" id="phone" name="phone" required />}
                    </InputMask>
                  </div>
                  <button type="submit">Отправить</button>
                  <button type="button" className="cancel-button" onClick={() => setShowForm(false)}>Отмена</button>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;