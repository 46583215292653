import React from 'react';
import Header from './components/Header';
import About from './components/About';
import Gallery from './components/Gallery';
import Services from './components/Services';
import Contact from './components/Contact';
import './App.css';
import Plusi from './components/Plusi';
import Uslugi from './components/Uslugi';
import UslugiDetails from './components/UslugiDetails';

const App = () => (
  <div className="App">
    <Header />
    <main>
      <Uslugi/>
      <Services />
      <Plusi />
      <UslugiDetails/>
      <About />
      <Gallery />
      <Contact />
    </main>
  </div>
);

export default App;