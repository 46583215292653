import React from 'react';
import { YMaps, Map, Polygon } from '@pbe/react-yandex-maps';
import './ServiceArea.css';

const ServiceArea = () => {
    const coordinates = [
        [45.95, 41.98], [46.15, 42.27], [46.27, 42.55], [46.32, 42.90],
        [46.20, 43.30], [46.30, 43.65], [46.17, 44.05], [46.27, 44.50],
        [46.10, 44.80], [45.85, 45.15], [45.60, 45.35], [45.35, 45.20],
        [45.05, 45.35], [44.80, 45.60], [44.65, 45.40], [44.40, 45.50],
        [44.25, 45.30], [44.05, 45.40], [43.95, 45.20], [43.85, 44.90],
        [43.95, 44.60], [43.80, 44.30], [43.90, 44.00], [43.80, 43.70],
        [44.00, 43.45], [43.95, 43.15], [44.15, 42.95], [44.10, 42.70],
        [44.30, 42.45], [44.22, 42.20], [44.40, 41.95], [44.60, 41.80],
        [44.80, 41.70], [45.00, 41.60], [45.20, 41.55], [45.40, 41.65],
        [45.60, 41.75], [45.80, 41.85], [45.95, 41.98]
      ]
  return (
    <section id="service-area">
      <h2>Зона обслуживания</h2>
      <h1>г.Ставрополь и Ставропольский край</h1>
      <YMaps>
        <Map 
          defaultState={{ 
            center: [45.05, 43.5], 
            zoom: 7 
          }} 
          width="100%" 
          height="400px"
        >
          <Polygon
            geometry={[coordinates]}
            options={{
              fillColor: '#00FF00',
              strokeColor: '#008000',
              opacity: 0.3,
              strokeWidth: 3,
            }}
          />
        </Map>
      </YMaps>
    </section>
  );
};

export default ServiceArea;