import React, { useState, useEffect } from 'react';
import './Uslugi.css';
import cuttingVideo from './images/rezvid.mp4';
import drillingVideo from './images/burvid.mp4';
import demolitionVideo from './images/demvid.mp4';

const Uslugi = () => {
  const uslugi = [
    { video: cuttingVideo, text: "Алмазная резка" },
    { video: drillingVideo, text: "Алмазное бурение" },
    { video: demolitionVideo, text: "Демонтаж" },
  ];

  const [loading, setLoading] = useState(Array(uslugi.length).fill(true));

  const handleVideoLoaded = (index) => {
    const newLoadingState = [...loading];
    newLoadingState[index] = false;
    setLoading(newLoadingState);
  };

  return (
    <section id="uslugi" className="uslugi-section">
      <div className="uslugi-container">
        {uslugi.map((usluga, index) => (
          <div key={index} className="uslugi-item">
            <div className="video-container">
              {loading[index] && (
                <div className="spinner">
                  <div className="spinner-inner"></div>
                </div>
              )}
              <video
                autoPlay
                loop
                muted
                playsInline
                className="uslugi-video"
                onLoadedData={() => handleVideoLoaded(index)}
                style={{ display: loading[index] ? 'none' : 'block' }}
              >
                <source src={usluga.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="text-overlay">
                <p>{usluga.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Uslugi;