import React, { useState, useEffect, useRef } from 'react';
import './Gallery.css';
import galery1 from "./images/zi2.jpg";
import galery2 from "./images/zi1.jpg";
import galery3 from "./images/zi3.jpg";
import galery4 from "./images/zi4.jpg";
import galery5 from "./images/zi6.jpg";
import galery6 from "./images/zi7.jpg";
import galery7 from "./images/zi8.jpg";
import galery8 from "./images/zi9.jpg";
import galery9 from "./images/zi10.jpg";
import galery10 from "./images/zi11.jpg";
import galery11 from "./images/zi12.jpg";
import video1 from "./images/burvid.mp4";
import video2 from "./images/demvid.mp4";
import video3 from "./images/rezvid.mp4";
import video4 from "./images/vid4.mp4";
import video5 from "./images/vid5.mp4";

const Gallery = () => {
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const mobileMediaRef = useRef(null);

  const mediaItems = [
    { type: 'image', src: galery1 },
    { type: 'video', src: video3 },
    { type: 'image', src: galery2 },
    { type: 'image', src: galery3 },
    { type: 'video', src: video1 },
    { type: 'image', src: galery4 },
    { type: 'image', src: galery5 },
    { type: 'image', src: galery6 },
    { type: 'video', src: video2 },
    { type: 'image', src: galery7 },
    { type: 'image', src: galery8 },
    { type: 'image', src: galery9 },
    { type: 'image', src: galery10 },
    { type: 'image', src: galery11 },
    { type: 'video', src: video4 },
    { type: 'video', src: video5 },
  ];

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const openMedia = (media) => {
    setSelectedMedia(media);
  };

  const closeMedia = () => {
    setSelectedMedia(null);
  };

  const scrollMedia = (direction) => {
    setCurrentIndex((prevIndex) => {
      let newIndex;
      if (direction === 'left') {
        newIndex = prevIndex - 1;
        if (newIndex < 0) newIndex = mediaItems.length - 1;
      } else {
        newIndex = (prevIndex + 1) % mediaItems.length;
      }

      if (mobileMediaRef.current) {
        mobileMediaRef.current.style.transition = 'transform 0.5s ease';
        mobileMediaRef.current.style.transform = `translateX(-${newIndex * 100}%)`;
      }

      return newIndex;
    });
  };

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (mobileMediaRef.current) {
        if (currentIndex === 0) {
          mobileMediaRef.current.style.transition = 'none';
          mobileMediaRef.current.style.transform = 'translateX(0)';
        } else if (currentIndex === mediaItems.length - 1) {
          mobileMediaRef.current.style.transition = 'none';
          mobileMediaRef.current.style.transform = `translateX(-${(mediaItems.length - 1) * 100}%)`;
        }
      }
    };

    const mediaContainer = mobileMediaRef.current;
    if (mediaContainer) {
      mediaContainer.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => {
      if (mediaContainer) {
        mediaContainer.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [currentIndex, mediaItems.length]);

  const renderMediaItem = (item, index) => {
    if (item.type === 'image') {
      return (
        <img
          key={index}
          src={item.src}
          alt={`Gallery item ${index + 1}`}
          onClick={() => openMedia(item)}
        />
      );
    } else if (item.type === 'video') {
      return (
        <video
          key={index}
          src={item.src}
          onClick={() => openMedia(item)}
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
        />
      );
    }
  };

  const handleMediaClick = (e) => {
    e.stopPropagation();
    if (e.target === e.currentTarget || e.target.tagName === 'VIDEO' || e.target.tagName === 'IMG') {
      closeMedia();
    }
  };

  return (
    <section id="gallery">
      <h2>Наша галерея</h2>
      {isMobile ? (
        <div className="mobile-gallery">
          <button
            className="scroll-button left"
            onClick={() => scrollMedia('left')}
            aria-label="Scroll left"
          >
            ◀
          </button>
          <div className="mobile-media-container">
            <div className="mobile-media" ref={mobileMediaRef}>
              {mediaItems.map((item, index) => renderMediaItem(item, index))}
            </div>
          </div>
          <button
            className="scroll-button right"
            onClick={() => scrollMedia('right')}
            aria-label="Scroll right"
          >
            ▶
          </button>
        </div>
      ) : (
        <div className="gallery">
          {mediaItems.map((item, index) => renderMediaItem(item, index))}
        </div>
      )}
      {selectedMedia && (
        <div className="fullscreen-media" onClick={handleMediaClick}>
          {selectedMedia.type === 'image' ? (
            <img src={selectedMedia.src} alt="Fullscreen" />
          ) : (
            <video 
              src={selectedMedia.src} 
              autoPlay 
              loop 
              muted
              preload="auto"
            />
          )}
        </div>
      )}
    </section>
  );
};

export default Gallery;