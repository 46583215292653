import React from 'react';
import { FaTelegramPlane, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';
import './Contact.css';
import ServiceArea from './ServiceArea';

const Contact = () => {

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/+79624448878`, '_blank');
  };
  const handleCallClick = () => {
    const callLink = document.createElement('a');
    callLink.href = 'tel:+79624448878';
    callLink.click();
  };
  

  return (
    <section id="contact">
      <h1>Свяжитесь с Нами</h1>
      <div className="contact-icons">
        <FaWhatsapp onClick={handleWhatsAppClick} className="iconfoot" />
        <FaPhoneAlt onClick={handleCallClick} className="icontelfoot" />
      </div>
      <ServiceArea/>
    </section>
  );
};
export default Contact;