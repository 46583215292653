import React from 'react';
import './UslugiDetails.css';
import ImageSlider from './ImageSlider';
import cut1 from "./images/cut1.jpg"
import cut2 from "./images/cut2.jpg"
import cut3 from "./images/cut3.jpg"
import driling1 from "./images/driling1.jpg"
import driling2 from "./images/driling2.jpg"
import driling3 from "./images/driling3.jpg"
import demolit1 from "./images/demolit1.jpg"
import demolit2 from "./images/demolit2.jpg"
import demolit3 from "./images/demolit3.jpg"


const UslugiDetails = () => {
    const cutImg = [
        cut1,
        cut2,
        cut3,
      ];
      const drilingImg = [
        driling1,
        driling2,
        driling3,
      ];
      const demolitImg = [
        demolit1,
        demolit2,
        demolit3,
      ];
      const uslugiDetails = [
        {
          component: <ImageSlider images={cutImg} />,
          title: "Алмазная резка строительных конструкций",
          description: "Алмазная резка - это высокотехнологичный метод обработки твердых строительных материалов, таких как бетон, железобетон и кирпич. Процесс использует специальные инструменты с алмазным напылением, которые вращаются на высокой скорости, позволяя выполнять точные и чистые разрезы. Алмазные диски или канаты, используемые в этой технологии, способны разрезать материал с минимальным образованием пыли и вибрации. Это позволяет проводить работы в жилых помещениях и на объектах, где важно сохранить чистоту и целостность окружающих конструкций. Алмазная резка может применяться для создания проемов любой формы и размера, а также для демонтажа крупных железобетонных конструкций."
        },
        {
          component: <ImageSlider images={drilingImg} />,
          title: "Алмазное сверление строительных конструкций",
          description: "Алмазное сверление (или бурение) - это метод создания точных цилиндрических отверстий в твердых строительных материалах. Процесс использует специальные буровые установки с алмазными коронками. Эти коронки представляют собой полые цилиндры, края которых усеяны алмазными сегментами. При вращении коронки алмазные сегменты постепенно разрушают материал, формируя идеально круглое отверстие. Важной особенностью алмазного бурения является использование водяного охлаждения, которое не только продлевает срок службы инструмента, но и значительно снижает образование пыли. Алмазное сверление позволяет создавать отверстия различного диаметра - от нескольких миллиметров до более метра, и на любую глубину, ограниченную лишь длиной бурового оборудования."
        },
        {
          component: <ImageSlider images={demolitImg} />,
          title: "Демонтажные работы",
          description: "Демонтажные работы представляют собой комплекс мероприятий по разборке и удалению существующих конструкций или их частей. Это может включать в себя снос стен, удаление перекрытий, разборку фундаментов и другие подобные операции. Современные методы демонтажа предполагают использование специализированной техники и инструментов, таких как отбойные молотки, гидравлические ножницы, экскаваторы с различными навесными устройствами. В зависимости от условий и требований проекта, демонтаж может проводиться вручную (для небольших объемов работ или в стесненных условиях) или с применением тяжелой техники (для масштабных проектов). Важным аспектом демонтажных работ является соблюдение техники безопасности и минимизация воздействия на окружающую среду, что достигается путем использования современных технологий и методов работы."
        }
      ];
  return (
    <section id="usldet" className="uslugi-details">
        <h1>Подробнее о нашей работе</h1>
      {uslugiDetails.map((usluga, index) => (
        <div key={index} className="uslugi-detail-item">
           {usluga.component}
          <h2>{usluga.title}</h2>
          <p>{usluga.description}</p>
        </div>
      ))}
    </section>
  );
};

export default UslugiDetails;