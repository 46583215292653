import React from 'react';
import './Plusi.css';

const advantages = [
  'Работа с организациями',
  'Наличный и безналичный рассчёт',
  'Скорость выполнения работ',
  'Минимальное количество пыли и шума',
  'Без вибрации и ударной нагрузки',
  'Возможность резки любых материалов',
  'Безопасность и надежность процесса',
 
];

const Plusi = () => (
  <section id="advantages">
    <h1>Наши Преимущества</h1>
    <ul className="advantages-list">
      {advantages.map((advantage, index) => (
        <li key={index}>{advantage}</li>
      ))}
    </ul>
  </section>
);

export default Plusi;