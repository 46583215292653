import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about">
      <h1>О нас</h1>
      <p>Мы - эксперты в области алмазной резки и демонтажа. С многолетним опытом и современным оборудованием, мы обеспечиваем высочайшее качество работ и безопасность.</p>
    </section>
  );
};

export default About;