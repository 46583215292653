import React, { useState, useEffect, useRef } from 'react';
import './Services.css';
import sawBlade from './images/circpila.png';
import stena from "./images/stena.png";

const services = [
  'Алмазную резку',
  'Корректировку проёмов',
  'Резку дверных проемов',
  'Резку оконных проемов',
  'Резку плит перекрытия',
  'Резку бетона и асфальта',
  'Бурение отверстий всех диаметров',
  'Установку химических анкеров', 
  'Подготовку квартиры или дома под ремонт'
];

const Services = () => {
  const [isRotating, setIsRotating] = useState(false);
  const [showSparks, setShowSparks] = useState(false);
  const sawBladeRef = useRef(null);
  const sparksRef = useRef(null);

  const handleMouseEnter = () => {
    setIsRotating(true);
    setShowSparks(true);
  };

  const handleMouseLeave = () => {
    setIsRotating(false);
    setShowSparks(false);
  };

  const handleServiceMouseEnter = () => {
    setIsRotating(true);
    setShowSparks(true);
  };

  const handleServiceMouseLeave = () => {
    setIsRotating(false);
    setShowSparks(false);
  };

  const handleServiceClick = () => {
    setIsRotating(true);
    setShowSparks(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsRotating(true);
            setShowSparks(true);
            setTimeout(() => {
              setIsRotating(false);
              setShowSparks(false);
            }, 1000);
          }
        });
      }, { threshold: 0.1 }
    );
    if (sawBladeRef.current) {
      observer.observe(sawBladeRef.current);
    }
    return () => {
      if (sawBladeRef.current) {
        observer.unobserve(sawBladeRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isRotating) {
      const timeoutId = setTimeout(() => {
        setIsRotating(false);
        setShowSparks(false);
      }, 1000); 
      return () => clearTimeout(timeoutId);
    }
  }, [isRotating]);

  return (
    <section id="services">
      <h1 className='service_h1'>Обращайтесь, если нужно сделать</h1>
      <div className="services-container">
        <div className='contpil'>
          <div className="saw-blade-container">
            <div 
              ref={sawBladeRef}
              className={`saw-blade ${isRotating ? 'rotating' : ''}`} 
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave}
              onTouchStart={handleServiceClick}
            >
              <img src={sawBlade} alt="Циркулярная пила" />
            </div>
            <div 
              ref={sparksRef} 
              className={`sparks-container ${showSparks ? 'sparks-active' : ''}`}
            >
              <div className="sparks">
                {Array.from({ length: 30 }).map((_, i) => (
                  <div key={i} style={{ '--randX': Math.random(), '--randY': Math.random() }}></div>
                ))}
              </div>
            </div>
          </div>
          <img className="stenaimg" src={stena} alt="Стена" />
        </div>
        <div className="services-list">
          {services.map((service, index) => (
            <div 
              className="service-item" 
              key={index}
              onMouseEnter={handleServiceMouseEnter} 
              onMouseLeave={handleServiceMouseLeave}
              onTouchStart={handleServiceClick}
            >
              <div className="circle"></div>
              <span className="service-name">{service}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;